import React, { useEffect } from 'react'
import ErrorPage from './Error'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import * as campaignActions from '../../redux/actions/campaignActions';

import Grid from '../Grid'
import SEO from '../seo'

const Campaign = (props) => {
  let supplier
  const intl = useIntl()
  const { slug, page } = props
  const campaign = useSelector(state => state.campaign)
  const _sl_close = Cookies.get('_sl_close')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(campaignActions.fetchCampaign('slug', slug))
    if (!page) navigate(`/campaigns/${slug}/page/1`)
  }, [dispatch, slug, page, _sl_close])

  if (campaign.status && campaign.status !== 200) return <ErrorPage code={campaign.status} />
  if (campaign.status && campaign.status === 200 && campaign.payload && !campaign.payload.enable) return <ErrorPage code={403} />
  if (campaign.payload.supplier && campaign.payload.supplier.name) supplier = campaign.payload.supplier.name
  
  return (
    <React.Fragment>
      <SEO title={`${campaign.payload.title} by ${supplier}`} lang={Cookies.get('lang') || 'en'} />
      <div className="campaign-page-content">
          <div>
              <div className="alert alert-success sl-success-box" role="alert">
                {intl.formatMessage({ id: 'campaign.start' })}
              </div>
          </div>
          <Grid campaign={campaign.payload} slug={slug} {...props} />
      </div>
    </React.Fragment>
  )
}

export default Campaign;