import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Loading = (props) => {
  const images = useStaticQuery(graphql`
    query {
      slLoader: file(relativePath: { eq: "sl-loader.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      {props.loader &&
        <div className="loader-container loading">
          <Img fixed={images.slLoader.childImageSharp.fixed} alt="Loading icon" />
        </div>
      }
    </React.Fragment>
  )
}

export default Loading