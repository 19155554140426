import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const requestBooks = () => {
  return {
    type: actionTypes.REQUEST_BOOKS
  }
};

export const fetchBooksSuccess = (payload, status, headers) => {
  return {
    type: actionTypes.FETCH_BOOKS_SUCCESS,
    payload,
    status,
    headers
  }
};

export const fetchBooksFailure = (error, status) => {
  return {
    type: actionTypes.FETCH_BOOKS_FAILURE,
    error,
    status
  }
};

export const fetchBooks = (slug, limit, page) => {
  return async (dispatch) => {
    dispatch(requestBooks())
    try {
      const response = await Axios.get(`${process.env.GATSBY_PROXY_API_URL}/public/publish/campaigns/slug/${slug}/items?page=${page}&limit=${limit}`)
      dispatch(fetchBooksSuccess(response.data, response.status, response.headers));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      dispatch(fetchBooksFailure(error.toString() || error, status));
    }
  };
};
