import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const requestCheckout = () => {
  return {
    type: actionTypes.REQUEST_CHECKOUT
  }
};

export const verifyCheckoutSuccess = (payload, status) => {
  return {
    type: actionTypes.VERIFY_CHECKOUT_SUCCESS,
    payload,
    status
  }
};

export const verifyCheckoutFailure = (error, status) => {
  return {
    type: actionTypes.VERIFY_CHECKOUT_FAILURE,
    error,
    status
  }
};

export const verifyCheckout = (params) => {
  return async (dispatch) => {
    dispatch(requestCheckout())
    try {
      const response = await Axios.post(`${process.env.GATSBY_PROXY_API_URL}/soap/buy`, params, { timeout: 30000 })
      dispatch(verifyCheckoutSuccess(response.data, response.status));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      const message = (error.response && error.response.data && error.response.data.ResultMessage) ? error.response.data.ResultMessage : error.toString()
      dispatch(verifyCheckoutFailure(message, status));
    }
  };
};
