import React from 'react'
import { Layout } from '../layout'

class PublicRoute extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  
  componentDidMount() {}

  render() {
    const { component: Component, location, ...rest } = this.props
    return (
      <Layout>
        <Component {...rest} />
      </Layout>
    )
  }
}

export default PublicRoute
