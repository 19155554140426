import React, { useState } from 'react';
import { useLocation, navigate } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Cookies from 'js-cookie';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Switch from 'react-switch';

const CookieBanner = () => {
	const [consents, setConsents] = useState({ google: false });

	const expireDate = moment().add(12, 'months').toDate();
	const location = useLocation();
	const _sl_close = Cookies.get('_sl_close');

	if (typeof window !== 'undefined') initializeAndTrack(location);

	if (_sl_close) return (<div></div>);

	const handleAccept = function () {
		if (consents.google) Cookies.set('gdpr-google-tagmanager', true, { path: '/', expires: expireDate });
		Cookies.set('_sl_close', true, { path: '/', expires: expireDate });
		navigate();
	}

	const handleAcceptAll = function () {
		Cookies.set('gdpr-google-tagmanager', true, { path: '/', expires: expireDate });
		Cookies.set('_sl_close', true, { path: '/', expires: expireDate });
		navigate();
	}

	const handleDecline = function () {
		Cookies.set('_sl_close', true, { path: '/', expires: expireDate });
		navigate();
	}

	return (
	    <div className="sl-cookie-banner">
	    	<div>
	            <h3><FormattedMessage id="cookies.banner.title" /></h3>
	            <p>
	            	<FormattedMessage 
						id="cookies.banner.description"
						values={{ 
							link: (<a href="https://www.streetlib.com/privacy" aria-label="Privacy"><FormattedMessage id="cookies.banner.linkprivacy" /></a>)
						}}
					/>
	            </p>
	            <ul>
	            	<li>
	            		<label htmlFor="necessay">
							<Switch 
								onChange={handleAccept}
								disabled={true}
								checked={true}
								onColor="#148EA0"
							/>
							<span><FormattedMessage id="cookies.banner.necessary" /></span>
						</label>
					</li>
					<li>
	            		<label htmlFor="google">
							<Switch 
								onChange={value => {
									setConsents({ google: value });
								}}
								checked={consents.google}
								onColor="#148EA0"
							/>
							<span><FormattedMessage id="cookies.banner.google" /></span>
						</label>
					</li>
	            </ul>
	            <div className="button-container">
	            	<button onClick={ () => { handleAcceptAll() } } className="btn btn-primary btn-lg" aria-label="Accept All"><FormattedMessage id="cookies.banner.acceptall" /></button>
	            	<button onClick={ () => { handleAccept() } } className="btn btn-primary btn-lg" aria-label="Accept"><FormattedMessage id="cookies.banner.accept" /></button>
		            <button onClick={ () => { handleDecline() } } className="btn btn-primary btn-lg" aria-label="Decline"><FormattedMessage id="cookies.banner.decline" /></button>
		        </div>
	        </div>
	    </div>
	)
}

export default CookieBanner;
