import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql, Link } from 'gatsby'
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import placeholder from '../../../images/cover.svg'
import { getBookFormats } from '../../../utils'

const Grid = ({ book, slug }) => {
	const intl = useIntl();

	const [hovered, setHovered] = useState(false);

	const data = useStaticQuery(graphql`
		query GridFormatsQuery {
			allPublishFormats {
				nodes {
					formatId
					description
					extension
					id
					name
					icon
				}
			}
		}
	`)

	book.availableFormats = getBookFormats(book.formats, data)

	const thumbnails = (book.cover && book.cover.thumbnails) ? book.cover.thumbnails : []
	let cover = _.find(thumbnails, { cover_size: 'LARGE' })
	if (!cover) cover = _.find(thumbnails, { cover_size: 'MEDIUM' })
	const bookCover = (cover && cover.url) ? cover.url : placeholder

	return (
		<div className="book" onMouseEnter={() => setHovered(!hovered)} onMouseLeave={() => setHovered(!hovered)} role="link" tabIndex="0">
			<Link to={`/campaigns/${slug}/books/${book.uuid}`} className="cover">
				<img src={bookCover} alt="Book cover" />
			</Link>
			<ul className="sl-inline-formats">
				{book.availableFormats.map(format =>
					<li key={format.formatId} data-tip={format.description} data-for={`gridTooltip-${book.uuid}`}><i className={`${format.icon} fa-lg`} /></li>
				)}
			</ul>
			<Link to={`/campaigns/${slug}/books/${book.uuid}`}>
				<h3 className={!hovered ? "truncate-title" : ""}>{book.title}</h3>
			</Link>
			<Link to={`/campaigns/${slug}/books/${book.uuid}`} className="btn btn-primary btn-lg mb-3">{intl.formatMessage({ id: 'book.grid.select' })}</Link>
			<ReactTooltip id={`gridTooltip-${book.uuid}`} clickable={true} effect="solid" />
		</div>
	)
}

export default Grid