import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'

const Footer = (props) => {
	const intl = useIntl();
	const { itemId } = props;
	const campaign = useSelector(state => state.campaign)

	const handleBackButton = (e) => {
		navigate(-1)
	}

    return (
		<React.Fragment>
			<footer className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 d-flex">
                            <p className="mb-0">
                                <FormattedMessage
                                    id="footer.power"
                                    values={{
                                        link: <a href="https://www.streetlib.com/" className="sl-color" target="_blank" rel="noreferrer">StreetLib</a>
                                    }}
                                />
                                { campaign.payload && campaign.payload.supplier && <span> | {intl.formatMessage({ id: 'footer.sponsor' })} <strong>{campaign.payload.supplier.name}</strong></span>}</p>
                        </div>
                        {itemId &&
                            <div className="col-md-4">
                                <button onClick={handleBackButton} className="back-btn" aria-label="Back"><i className="fas fa-level-down-alt fa-rotate-90 fa-lg" /></button>
                            </div>
                        }
                    </div>
                </div>
			</footer>
		</React.Fragment>
	)
}

export default Footer