import React, { useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Cookies from 'js-cookie';

import ErrorPage from './Error'
import SEO from '../seo'
import CouponForm from '../CouponForm'
import BookDetails from '../BookDetails'
import Loading from '../Loading'
import * as campaignActions from '../../redux/actions/campaignActions';
import * as bookActions from '../../redux/actions/bookActions';

const Book = (props) => {
  const { slug, itemId } = props;
  const campaign = useSelector(state => state.campaign)
  const book = useSelector(state => state.book)
  const _sl_close = Cookies.get('_sl_close')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(campaignActions.fetchCampaign('slug', slug))
    dispatch(bookActions.fetchBookById(slug, itemId))
  }, [dispatch, slug, itemId, _sl_close]);

  if (campaign.status && campaign.status === 200 && campaign.payload && !campaign.payload.enable) return <ErrorPage code={403} />
  if (book.status && book.status !== 200) return <ErrorPage code={book.status} />

  return (
    <React.Fragment>
      <SEO title={`Get your free book: ${book.payload.title}`} lang={Cookies.get('lang') || 'en'} />
      <Loading loader={book.loading}/>
      <div className="book-page-content d-md-flex flex-md-equal">
          <CouponForm book={book.payload} slug={slug} />
          <BookDetails book={book.payload} campaign={campaign.payload} error={book.error} />
      </div>
    </React.Fragment>
  )
}

export default Book