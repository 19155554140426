import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const requestOrderById = () => {
  return {
    type: actionTypes.REQUEST_ORDER_BY_ID
  }
};

export const fetchOrderByIdSuccess = (payload, status) => {
  return {
    type: actionTypes.FETCH_ORDER_BY_ID_SUCCESS,
    payload,
    status
  }
};

export const fetchOrderByIdFailure = (error, status) => {
  return {
    type: actionTypes.FETCH_ORDER_BY_ID_FAILURE,
    error,
    status
  }
};

export const fetchOrderById = (id, qs) => {
  return async (dispatch) => {
    dispatch(requestOrderById())
    try {
      const response = await Axios.get(`${process.env.GATSBY_PROXY_API_URL}/public/publish/orders/book/${id}${qs}`)
      dispatch(fetchOrderByIdSuccess(response.data, response.status));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      dispatch(fetchOrderByIdFailure(error.toString() || error, status));
    }
  };
};
