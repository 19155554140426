import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const resetCheckout = () => {
  return {
    type: actionTypes.RESET_CHECKOUT
  }
};

export const resetCampaign = () => {
  return {
    type: actionTypes.RESET_CAMPAIGN
  }
};

export const requestCampaign = () => {
  return {
    type: actionTypes.REQUEST_CAMPAIGN
  }
};

export const fetchCampaignSuccess = (payload, status) => {
  return {
    type: actionTypes.FETCH_CAMPAIGN_SUCCESS,
    payload,
    status
  }
};

export const fetchCampaignFailure = (error, status) => {
  return {
    type: actionTypes.FETCH_CAMPAIGN_FAILURE,
    error,
    status
  }
};

export const fetchCampaign = (type, value) => {
  return async (dispatch) => {
    dispatch(resetCheckout())
    dispatch(requestCampaign());
    try {
      const response = await Axios.get(`${process.env.GATSBY_PROXY_API_URL}/public/publish/campaigns/${type}/${value}`)
      dispatch(fetchCampaignSuccess(response.data, response.status));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      dispatch(fetchCampaignFailure(error.toString() || error, status));
    }
  };
};