import React from 'react'
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import SEO from '../seo'

const Tos = (props) => {
  
  const intl = useIntl();

  return (
    <React.Fragment>
      <SEO title={intl.formatMessage({ id: 'tos.meta.title' })} lang={Cookies.get('lang') || 'en'} />
      <div className="tos-page-content">
        <div className="row sl-hero">
          <div className="col-md-12 text-center mb-4">
            <div>
              <h1>{intl.formatMessage({ id: 'tos.title' })}</h1>
            </div>
          </div>
        </div>
        <section className="row sl-section">
          <div className="col-md-12 mb-4">
            <div>
              <h4>{intl.formatMessage({ id: 'tos.subtitle' })}</h4>
            </div>
          </div>
        </section>
        <section className="row sl-section">
          <div className="col-md-12">
            <ol className="sl-ordered-list-style">
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-1.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-1.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-1.2' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-2.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-2.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-2.2' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-2.3' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-2.4' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-3.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-3.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-3.2' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-3.3' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-3.4' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-4.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-4.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-4.2' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-4.3' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-4.4' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-4.5' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-5.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-5.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-5.2' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-5.3' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-6.title' })}</h5>
                <ol className="sl-list-style-none">
                  <li><p>{intl.formatMessage({ id: 'tos.section-6.1' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-7.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-7.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-7.2' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-7.3' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-8.title' })}</h5>
                <ol className="sl-list-style-none">
                  <li><p>{intl.formatMessage({ id: 'tos.section-8.1' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-9.title' })}</h5>
                <ol className="sl-list-style-none">
                  <li><p>{intl.formatMessage({ id: 'tos.section-9.1' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-10.title' })}</h5>
                <ol>
                  <li><p>{intl.formatMessage({ id: 'tos.section-10.1' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'tos.section-10.2' })}</p></li>
                </ol>
              </li>
              <li>
                <h5>{intl.formatMessage({ id: 'tos.section-11.title' })}</h5>
                <ol className="sl-list-style-none">
                  <li><p>{intl.formatMessage({ id: 'tos.section-11.1' })}</p></li>
                </ol>
              </li>
            </ol>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Tos