import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import SEO from '../seo'

const Privacy = (props) => {
  
  const intl = useIntl();
  
  const [lang, setLang] = useState(Cookies.get('lang') || 'en');
  
  const coownershipAgreementEN = "https://streetlib-agreements.s3.eu-west-1.amazonaws.com/Sintesi_accordo_contitolarita_IT_20210901.pdf";
  const coownershipAgreementIT = "https://streetlib-agreements.s3.eu-west-1.amazonaws.com/Sintesi_accordo_contitolarita_IT_20210901.pdf";
  
  const coownershipAgreement =  (lang === 'en') ? coownershipAgreementEN : coownershipAgreementIT;

  return (
    <React.Fragment>
      <SEO title={intl.formatMessage({ id: 'privacy.meta.title' })} lang={Cookies.get('lang') || 'en'} />
      <div className="privacy-page-content">
        <div className="row sl-hero">
          <div className="col-md-12 text-center mb-4">
            <div>
              <h1>{intl.formatMessage({ id: 'privacy.title' })}</h1>
            </div>
          </div>
        </div>

        <section className="row sl-section mb-5">
          <div className="col-md-12">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.subtitle' })}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <p>{intl.formatMessage({ id: 'privacy.section-1.1' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.section-1.2' })}</p>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <p>{intl.formatMessage({ id: 'privacy.section-2.1' })}</p>
              <ul className="sl-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.3' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.4' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.5' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.6' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.7' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.8' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.9' })}</p>
                {
                  (lang === 'en') &&
                    <ul>
                      <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.9.1' })}</p></li>
                    </ul>
                  }
                </li>
              </ul>
              {
                  (lang === 'it') &&
                  <ul className="sl-list-style">
                    <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.10' })}</p>
                        <ul>
                          <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.10.1' })}</p></li>
                          <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.10.2' })}</p></li>
                        </ul>
                    </li>
                    <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.11' })}</p>
                        <ul className="sl-list-style">
                          <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.11.1' })}</p></li>
                          <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.11.2' })}</p></li>
                        </ul>
                    </li>
                  </ul>
                }
            </div>
          </div>
          {
            (lang === 'it') &&
              <div className="col-md-12 mt-5">
                <div>
                  <p><b><a href={coownershipAgreement} rel="noreferrer" target="_blank" className="sl-color">{intl.formatMessage({ id: 'privacy.section-3.1' })}</a></b></p>
                </div>
              </div>
          }
        </section>

      </div>
    </React.Fragment>
  )
}

export default Privacy