import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const incrementDownloadSuccess = (payload, status) => {
  return {
    type: actionTypes.INCREMENT_DOWNLOAD_SUCCESS,
    payload,
    status
  }
};

export const incrementDownloadFailure = (error, status) => {
  return {
    type: actionTypes.INCREMENT_DOWNLOAD_FAILURE,
    error,
    status
  }
};

export const incrementDownload = (id, orderRowId, location) => {
  return async (dispatch) => {
    try {
      const data = { 
          increment_downloads: {
              user_agent: (typeof window !== `undefined`) ? window.navigator.userAgent : null,
              referrer: location
          }
      }

      const response = await Axios.post(`${process.env.GATSBY_PROXY_API_URL}/public/publish/orders/book/${id}/increment_downloads/${orderRowId}`, data)
      dispatch(incrementDownloadSuccess(response.data, response.status));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      dispatch(incrementDownloadFailure(error.toString() || error, status));
    }
  };
};
