import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from '@reach/router'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Cookies from 'js-cookie';
import * as campaignActions from '../../redux/actions/campaignActions'

import SEO from '../seo'

const Home = (props) => {
  const [coupon, setCoupon] = useState(props.code)

  const intl = useIntl();

  const images = useStaticQuery(graphql`
    query {
      homeImg: file(relativePath: { eq: "home-illustration.png" }) {
        childImageSharp {
          fixed(height: 420) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const textInput = React.createRef();

  useEffect(() => {
    if (textInput && textInput.current) textInput.current.focus();
  })

  const dispatch = useDispatch()
  const campaign = useSelector(state => state.campaign)

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(campaignActions.fetchCampaign('coupon', coupon))
  }

  const handleCouponChange = (e) => {
    e.preventDefault()
    setCoupon(e.target.value)
  }

  if (coupon && campaign.status === 200 && campaign.payload && campaign.payload.enable) Cookies.set('coupon', coupon)

  if (coupon && campaign.payload && campaign.payload.slug_key && campaign.payload.enable) return <Redirect to={`/campaigns/${campaign.payload.slug_key}`} noThrow />

  if (coupon && campaign.status === 200 && campaign.payload && !campaign.payload.enable) campaign.error = intl.formatMessage({ id: 'home.campaign.error' })

  return (
    <React.Fragment>
      <SEO title={intl.formatMessage({ id: 'home.meta.title' })} lang={Cookies.get('lang') || 'en'} />
      <div className="row sl-home-hero">
  			<div className="col-md-6">
          <div className="sl-home-hero-content px-xl-0">
            <h1>{intl.formatMessage({ id: 'home.title' })}</h1>
            <p>{intl.formatMessage({ id: 'home.subtitle' })}</p>
            {campaign.status === 404 && <p className="error-box home">{intl.formatMessage({ id: 'home.coupon.error' })}</p>}
            {campaign.error && campaign.status !== 404 && <p className="error-box home">{campaign.error} - Please contact our <a href="https://help.streetlib.com">support team</a></p>}
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3">
                  <input type="text" className="form-control input-coupon" id="couponNumber" ref={textInput}  placeholder={intl.formatMessage({ id: 'home.form.couponnumber' })} onChange={(e) => handleCouponChange(e)} disabled={campaign.loading} required/>
                </div>

                <button className={`btn btn-primary btn-lg btn-block mb-3 ${campaign.loading ? 'noHover' : ''}`} type="submit" disabled={campaign.loading}>{campaign.loading && <i className="fas fa-circle-notch fa-lg fa-spin" style={{marginRight: '10px'}} />}{intl.formatMessage({ id: 'home.form.usecoupon.btn'  })}</button>
            </form>
          </div>
        </div>
        <div className="col-md-6 sl-home-img">
				  <div>
            <Img fixed={images.homeImg.childImageSharp.fixed} alt="Home illustration" className="img-fluid" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home