import * as actionTypes from './actionTypes';
import Axios from 'axios';

export const requestBookById = () => {
  return {
    type: actionTypes.REQUEST_BOOK_BY_ID
  }
};

export const fetchBookByIdSuccess = (payload, status) => {
  return {
    type: actionTypes.FETCH_BOOK_BY_ID_SUCCESS,
    payload,
    status
  }
};

export const fetchBookByIdFailure = (error, status) => {
  return {
    type: actionTypes.FETCH_BOOK_BY_ID_FAILURE,
    error,
    status
  }
};

export const fetchBookById = (slug, id) => {
  return async (dispatch) => {
    dispatch(requestBookById())
    try {
      const response = await Axios.get(`${process.env.GATSBY_PROXY_API_URL}/public/publish/campaigns/slug/${slug}/items/${id}`)
      dispatch(fetchBookByIdSuccess(response.data, response.status));
    } catch (error) {
      const status = (error.response && error.response.status) ? error.response.status : 500;
      dispatch(fetchBookByIdFailure(error.toString() || error, status));
    }
  };
};
