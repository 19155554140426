import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';
import { useIntl, FormattedMessage } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import * as checkoutActions from '../../redux/actions/checkoutActions'
import AvailableFormats from '../AvailableFormats'
import { getBookFormats } from '../../utils'
import Cookies from 'js-cookie';

const drmTypes = [{
	code: 'none',
	icon: 'fas fa-book-open fa-lg',
	description: 'Open book'
},{
	code: 'drm',
	icon: 'fas fa-lock fa-lg',
	description: 'Adobe DRM'
},{
	code: 'watermark',
	icon: 'fas fa-highlighter fa-lg',
	description: 'Watermark inside'
},{
	code: 'drm+watermark',
	icon: 'fas fa-lock fa-lg',
	description: "Adobe DRM+Watermark"
},]

const CouponForm = ({ book, slug }) => {
	const intl = useIntl();

	const data = useStaticQuery(graphql`
		query FormatsQuery {
			allPublishFormats {
				nodes {
					formatId
					description
					extension
					id
					name
					icon
				}
			}
		}
	`)

	let availableFormats = getBookFormats(book.formats, data)

	const [format, setFormat] = useState(null)
	const [firstname, setFirstname] = useState(null)
	const [lastname, setLastname] = useState(null)
	const [email, setEmail] = useState(null)
	const [coupon, setCoupon] = useState(Cookies.get('coupon'))
	const lang = Cookies.get('lang')

	const checkout = useSelector(state => state.checkout)

	const dispatch = useDispatch()

	// if (!availableFormats.length) console.log('ERROR NO FORMATS')

	const mainProtectionId = (book.formats && book.formats[0] && book.formats[0].drm_type && book.formats[0].drm_type.id) ? book.formats[0].drm_type.id : 0

	useEffect(() => {
		ReactTooltip.rebuild()
	})

	const handleSubmit = (e) => {
		e.preventDefault()
		const params = {
			uuid: book.uuid,
			format: format,
			code: coupon,
			firstName: firstname,
			lastName: lastname,
			email: email,
			language: lang || 'en'
		}

		dispatch(checkoutActions.verifyCheckout(params))
	}

	const handleCouponChange = (e) => {
		e.preventDefault()
		// e.target.value = e.target.value.toUpperCase()
		setCoupon(e.target.value)
	}

	/*
	// Removed
	const handleDownload = (e, orderRow) => {
		e.preventDefault();
		console.log(orderRow)
		dispatch(incrementDownloadActions.incrementDownload(orderId, orderRow.RowId, location.href))

		fetch(orderRow.PathDownload, {
		    headers: new Headers({
		      'Origin': location.origin
		    }),
		    mode: 'cors'
		  })
		  .then(response => response.blob())
		  .then(blob => {
		    let blobUrl = window.URL.createObjectURL(blob);
		    let a = document.createElement('a');
		    a.download = orderRow.FileName + orderRow.FileExtension;
		    a.href = blobUrl;
		    a.target = '_self';
		    // For Firefox https://stackoverflow.com/a/32226068
		    document.body.appendChild(a);
		    a.click();
		    a.remove();
		  })
		  .catch(e => console.error(e));
	}
	*/

	// console.log(checkout)

	const orderData = (checkout.payload && checkout.payload.Rows && checkout.payload.Rows.OrderRowResult && checkout.payload.Rows.OrderRowResult[0]) ? checkout.payload.Rows.OrderRowResult[0] : null

	if (orderData) Cookies.remove('coupon')

	// const orderData = {"RowId":531,"PathDownload":"https://s3.eu-west-1.amazonaws.com/stealth-test-files/staging/assets/formats/1/s15/p48/2/28150309-82ff-469c-8122-80b217722a27.epub?X-Amz-Expires=500&response-content-disposition=filename%3D%22Una_Casa_Encantada_9783000132230_216.epub%22&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAI5TBINYBVNOTNYYQ/20200902/eu-west-1/s3/aws4_request&X-Amz-Date=20200902T132242Z&X-Amz-SignedHeaders=host&X-Amz-Signature=3f445b5b8864ffa2645e62d1672691d775dc0031b38a49bbb1501978c606ebe9","ItemId":1306,"FormatId":"5","ISBN":"9783000132230","ISBNPaper":"","FormatDescription":"5","Description":"ePub","ExtraContents":null,"FileName":"Una_Casa_Encantada_9783000132230_216","FileExtension":".epub"}
	
	// console.log(orderData)

	return (
		<div className="mr-md-2 sl-m-sm">
			<div className="sl-form-box">
				<div className="d-flex flex-row justify-content-between align-items-center">
					<h6 data-tip={drmTypes[mainProtectionId].description}><span>{intl.formatMessage({ id: 'book.form.protection' })}</span><i className={drmTypes[mainProtectionId].icon} /></h6>
					<div>
						<span className="sl-tag sl-bg-color">{intl.formatMessage({ id: 'book.form.free' })}</span>
					</div>
				</div>

				<div>
					<h1 className="book-title">{book.title}</h1>
					{orderData &&
						<div>
							<h3>{intl.formatMessage({ id: 'book.form.congratulations' })}</h3>
							<p>{intl.formatMessage({ id: 'book.form.ready'  })}</p>
						</div>
					}

					{!orderData &&
						<div>
							<form onSubmit={(e) => handleSubmit(e)}>
								<div className="mb-3 pb-2 d-flex flex-row justify-content-between align-items-center">
									{availableFormats.length
										? <AvailableFormats formats={availableFormats} setFormat={setFormat} />
										: <h6 style={{color: 'red'}}>{intl.formatMessage({ id: 'book.form.noformats'  })}</h6>
									}
								</div>

								{format &&
									<div className="mb-3">
										<h5 className="selected-format">
											<strong>{format}</strong>
										</h5>
									</div>
								}

								{checkout && checkout.error && <p className="error-box">{checkout.error}</p>}

								<div className="mb-3">
									<input type="text" className="form-control" id="firstName" placeholder={intl.formatMessage({ id: 'book.form.firstname' })} required disabled={checkout.loading} onChange={ e => setFirstname(e.target.value) } />
								</div>

								<div className="mb-3">
									<input type="text" className="form-control" id="lastName" placeholder={intl.formatMessage({ id: 'book.form.lastname' })} required disabled={checkout.loading} onChange={ e => setLastname(e.target.value) } />
								</div>

								<div className="mb-3">
									<input type="email" className="form-control" id="email" placeholder={intl.formatMessage({ id: 'book.form.email' })} required disabled={checkout.loading} onChange={ e => setEmail(e.target.value) } />
								</div>

								<div className="mb-3">
									<input type="text" className="form-control input-coupon" id="coupon" value={coupon || ''} placeholder={intl.formatMessage({ id: 'book.form.coupon' })} required disabled={checkout.loading} onChange={ e => handleCouponChange(e) } />
								</div>

								<div className="mb-3 form-check">
									 <input type="checkbox" className="form-check-input" id="ckeck-tos" required/>
                                      <label htmlFor="ckeck-tos">
                                      	<FormattedMessage
											id="tos.checkbox.label"
											values={{
												link: <a href="/tos">
													<FormattedMessage id="tos.checkbox.label.link" />
												</a>
											}}
										/>
                                      </label>
								</div>
                                
                                <div className="mb-3 form-check">
									 <input type="checkbox" className="form-check-input" id="ckeck-privacy" required/>
                                      <label htmlFor="ckeck-privacy">
                                      	<FormattedMessage
											id="privacy.checkbox.label"
											values={{
												link: <a href={`/campaigns/${slug}/privacy`}>
													<FormattedMessage id="privacy.checkbox.label.link" />
												</a>
											}}
										/>
                                      </label>
								</div>

								<button className={`btn btn-primary btn-lg btn-block mb-3 ${checkout.loading ? 'noHover' : ''}`} type="submit" disabled={checkout.loading}>{checkout.loading && <i className="fas fa-circle-notch fa-lg fa-spin" style={{marginRight: '10px'}} />}{intl.formatMessage({ id: 'book.form.getbook'  })}</button>
							</form>
							<div className="sl-form-notes">
								<p>{intl.formatMessage({ id: 'book.form.note'  })}</p>
							</div>
						</div>
					}
				</div>
			</div>
			<ReactTooltip effect="solid" />
		</div>
	)
}

export default CouponForm