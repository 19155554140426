import React, { useEffect } from 'react'
import ErrorPage from './Error'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import * as campaignActions from '../../redux/actions/campaignActions';

import SEO from '../seo'

const Campaign = (props) => {
  let supplier
  const intl = useIntl()
  const { slug } = props
  const campaign = useSelector(state => state.campaign)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(campaignActions.fetchCampaign('slug', slug))
  }, [dispatch, slug])

  if (campaign.status && campaign.status !== 200) return <ErrorPage code={campaign.status} />
  if (campaign.status && campaign.status === 200 && campaign.payload && !campaign.payload.enable) return <ErrorPage code={403} />
  if (campaign.payload.supplier && campaign.payload.supplier.name) supplier = campaign.payload.supplier.name
  
  return (
    <React.Fragment>
      <SEO title={`Privacy policy - ${campaign.payload.title} by ${supplier}`} lang={Cookies.get('lang') || 'en'} />
      <div className="privacy-page-content">
        <div className="row sl-hero">
          <div className="col-md-12 text-center mb-4">
            <div>
              <h1>{intl.formatMessage({ id: 'privacy.title' })}</h1>
            </div>
          </div>
        </div>
        <section className="row sl-section mb-5">
          <div className="col-md-12">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.subtitle' })}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <p>{intl.formatMessage({ id: 'privacy.section-1.1' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.section-1.2' })}</p>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <p>{intl.formatMessage({ id: 'privacy.section-2.1' })}</p>
              <ul className="sl-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.3' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.4' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.5' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.6' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.7' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.8' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.9' })}</p>
                    <ul>
                      <li><p>{intl.formatMessage({ id: 'privacy.section-2.list.9.1' })}</p></li>
                    </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="row sl-section sl-table">
          <div className="col-md-12">
            <div>
              <h3 className="mb-4">{intl.formatMessage({ id: 'privacy.table.title' })}</h3>
            </div>
          </div>
          <div className="col-md-12 sl-table-row">
            <div>
              <p>{intl.formatMessage({ id: 'privacy.table.row1.1' })}</p>
            </div>
          </div>
          <div className="col-md-12 sl-table-row">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.table.row2.title' })}</h4>
            </div>
            <div>
              <p>{intl.formatMessage({ id: 'privacy.table.row2.1' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.table.row2.2' })}</p>
              <ul className="sl-alpha-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list.3' })}</p></li>
              </ul>
              <p>{intl.formatMessage({ id: 'privacy.table.row2.3' })}</p>
              <ul>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list2.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list2.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list2.3' })}</p></li>
                  <li><p>{intl.formatMessage({ id: 'privacy.table.row2.list2.4' })}</p></li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 sl-table-row">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.table.row3.title' })}</h4>
            </div>
            <div>
              <p>{intl.formatMessage({ id: 'privacy.table.row3.1' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.table.row3.2' })}</p>
              <ul className="sl-alpha-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.table.row3.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row3.list.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row3.list.3' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row3.list.4' })}</p></li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 sl-table-row">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.table.row4.title' })}</h4>
            </div>
            <div>
              <p>{intl.formatMessage({ id: 'privacy.table.row4.1' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.table.row4.2' })}</p>
              <ul className="sl-alpha-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.table.row4.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row4.list.2' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row4.list.3' })}</p></li>
              </ul>
              <p>{intl.formatMessage({ id: 'privacy.table.row4.3' })}</p>
              <p>{intl.formatMessage({ id: 'privacy.table.row4.4' })}</p>
            </div>
          </div>
          <div className="col-md-12 sl-table-row">
            <div>
              <h4 className="mb-4">{intl.formatMessage({ id: 'privacy.table.row5.title' })}</h4>
            </div>
            <div>
              <p>{intl.formatMessage({ id: 'privacy.table.row5.1' })}</p>
              <ul className="sl-alpha-list-style">
                <li><p>{intl.formatMessage({ id: 'privacy.table.row5.list.1' })}</p></li>
                <li><p>{intl.formatMessage({ id: 'privacy.table.row5.list.2' })}</p></li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Campaign;