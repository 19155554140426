import React from 'react'
import _ from 'lodash'
import he from 'he'
import { useIntl } from 'react-intl';
import ClampLines from 'react-clamp-lines';
import placeholder from '../../images/cover.svg'

const BookDetails = ({ book, campaign, error }) => {
	const intl = useIntl();

	let authors = []
	authors = _.filter(book.contributors, { contributor_role: { id: 1 } })
	const categories = book.categories || []
	const thumbnails = (book.cover && book.cover.thumbnails) ? book.cover.thumbnails : []
	let cover = _.find(thumbnails, { cover_size: 'LARGE' })
	if (!cover) cover = _.find(thumbnails, { cover_size: 'MEDIUM' })
	const bookCover = (cover && cover.url) ? cover.url : placeholder

	return (
		<div className="w-100 sl-md-bg-container">
            <div className="d-md-flex flex-md-equal pr-md-3 sl-start sl-md-bg">
                <div className="mr-md-3 px-md-5 text-center sl-m-sm">
                    <div className="book-box">
                        <div className="book-cover" style={{'backgroundImage': `url(${bookCover})`}} ></div>
                    </div>
                </div>
                <div id="book-details-box" className="mr-md-3 text-white book-details">
                    <div>
                        <div>
                            <h3>{intl.formatMessage({ id: 'book.details.title'  })}</h3>
                            <p>{book.title}</p>
                        </div>
                        <div>
                            <h3>{intl.formatMessage({ id: 'book.details.author'  })}</h3>
                            <ul>
                            {authors.map((author) => 
                                <li key={author.id}>{author.composite_name}</li>
                            )}
                            </ul>
                        </div>
                        <div>
                            <h3>{intl.formatMessage({ id: 'book.details.genre'  })}</h3>
                            <ul>
                            {categories.map((category) => 
                                <li key={category.code}>{category.description}</li>
                            )}
                            </ul>
                        </div>
                        <div>
                            <h3>{(book.isbn) ? 'ISBN' : 'UUID'}</h3>
                            <p>{(book.isbn) ? book.isbn : book.uuid}</p>
                        </div>
                        <div>
                            <h3>{intl.formatMessage({ id: 'book.details.summary'  })}</h3>
                            {book.summary &&
                                <ClampLines
                                    text={he.decode(book.summary.replace(/(<([^>]+)>)/gi, ""))}
                                    id="book-summary-text"
                                    lines={8}
                                    ellipsis="..."
                                    moreText="Read more"
                                    lessText="Collapse"
                                    innerElement="p"
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default BookDetails