import React, { useEffect } from 'react'
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import ReactPaginate from 'react-paginate';
import * as booksActions from '../../redux/actions/booksActions';
import Loading from '../Loading'
import BookItem from './BookItem'

const Grid = (props) => {
	const intl = useIntl()
	let { page } = props

	const slug = props.slug
	const campaign = props.campaign
	const books = useSelector(state => state.books)
	let lgCol = 'col-md-3',
		limit = process.env.BOOKS_PER_PAGE || 8,
		pages = 0,
		total = 0,
		itemsCount = 0

	page = parseInt(page, 10) || 1

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(booksActions.fetchBooks(slug, limit, page))
	}, [dispatch, slug, page, limit])
	
	if (campaign.details && campaign.details.entitled_items && campaign.details.entitled_items.count) itemsCount = campaign.details.entitled_items.count
	if (itemsCount === 1 && books.payload && books.payload[0]) navigate(`/campaigns/${slug}/books/${books.payload[0].uuid}`)
    if (itemsCount === 2) lgCol = 'col-md-6'
	if (itemsCount === 3) lgCol = 'col-md-4'
	
	const col = `${lgCol} col-sm-6`

	// console.log(books)

	if (books.headers) {
		limit = books.headers['per-page']
		total = books.headers.total
		pages = Math.floor(total / limit) + 1
	}
    
    let paginationClasses = `sl-pagination-container`
    
    if (pages === 1) {
        paginationClasses = `${paginationClasses} sl-no-display`
    }
    
	const handlePageClick = (e) => {
		navigate(`/campaigns/${slug}/page/${e.selected + 1}`)
	}

	const handleHrefBuilder = (e) => {
		return `/campaigns/${slug}/page/${e}`
	}

	return (
		<React.Fragment>
			{books && books.payload && typeof books.payload !== 'string' &&
				<div>
					<Loading loader={books.loading}/>
					<div>
					  <ul className="row books-grid">
						{books.payload.map((book, index) => 
						  <li key={index} className={col}>
							<BookItem book={book} slug={slug} />
						  </li>
						)}
					  </ul>
					</div>
					<div className="row">
						<nav className={paginationClasses}>
							<ReactPaginate
								previousLabel={intl.formatMessage({ id: 'book.grid.previous' })}
								nextLabel={intl.formatMessage({ id: 'book.grid.next' })}
								breakLabel={'...'}
								pageClassName={'page-item'}
								pageLinkClassName={'page-link'}
								previousLinkClassName={'page-link'}
								nextLinkClassName={'page-link'}
								pageCount={pages}
								initialPage={page - 1}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								hrefBuilder={handleHrefBuilder}
								onPageChange={handlePageClick}
								containerClassName={'pagination'}
								activeClassName={'active'}
							/>
						</nav>
					</div>
				</div>
			}
		</React.Fragment>
	)
}

export default Grid