import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Cookies from 'js-cookie';
import { IntlContext } from '../IntlContext'

import itFlag from '../../images/it-flag.svg'
import usFlag from '../../images/us-flag.svg'
import ptFlag from '../../images/pt-flag.svg'

const Navbar = (props) => {
	const intl = useIntl();
    const { itemId, orderId } = props;
    const [lang, setLang] = useState(Cookies.get('lang') || 'en')
    const { switchToLocale } = React.useContext(IntlContext);
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

	const images = useStaticQuery(graphql`
		query {
		  logoImage: file(relativePath: { eq: "logo.png" }) {
		    childImageSharp {
		      fluid(maxWidth: 300) {
		        ...GatsbyImageSharpFluid
		      }
		    }
		  }
		}
	`)
    
    const toggleHandler = (selectedLang) => {
		Cookies.set('lang', selectedLang);
        setLang(selectedLang)
        switchToLocale(selectedLang)
	};
    

	return (
        <React.Fragment>
			<nav className={`site-header navbar navbar-expand-md navbar-light`}>
                <div className="container d-flex flex-row justify-content-between">
                    <a className="navbar-brand" href={`/`}><Img fluid={images.logoImage.childImageSharp.fluid} alt="StreetLib" className="logo" /></a>
                    <div className="d-flex align-items-center">
                        <div className={`collapse navbar-collapse ${open ? "show" : ""}`} id="navbarCollapse">
                            <ul className={`navbar-nav mr-auto ${itemId || orderId || props.path === '/' ? 'navbar-white' : ''}`}>
                                <li className="nav-item">
                                    <a className="nav-link" href="https://help.streetlib.com" target="_blank" rel="noreferrer"><i className="far fa-question-circle fa-lg" /><span>{intl.formatMessage({ id: 'header.nav.help' })}</span><i className="fa fa-external-link-alt"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="http://streetlib.com" target="_blank" rel="noreferrer"><span>{intl.formatMessage({ id: 'header.nav.about' })}</span><i className="fa fa-external-link-alt"></i></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " href={'/privacy'} rel="noreferrer"><span>{intl.formatMessage({ id: 'header.nav.privacy' })}</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link " href={'/tos'} rel="noreferrer"><span>{intl.formatMessage({ id: 'header.nav.tos' })}</span></a>
                                </li>
                                <li>
                                    <div className="sl-language-switcher">
                                        <button className="sl-language-switcher-btn" onClick={() => {setExpanded(!expanded)}}>
                                            <img src={lang === 'en' ? usFlag : (lang === 'it' ? itFlag : ptFlag)} alt="IT" />
                                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" fill="#bbb"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                                        </button>
                                        <div className={`collapse sl-language-switcher-menu ${expanded ? "show" : ""}`}>
                                          <ul>
                                                {lang !== 'it' && 
                                                    <li>
                                                        <button className="btn-link" onClick={() => toggleHandler('it')}>
                                                            <img src={itFlag} alt="IT" />
                                                            <span>Italia</span>
                                                        </button>
                                                    </li>
                                                }
                                                {lang !== 'en' &&
                                                    <li>
                                                        <button className="btn-link" onClick={() => toggleHandler('en')}>
                                                            <img src={usFlag} alt="EN" />
                                                            <span>World (English)</span>
                                                        </button>
                                                    </li>
                                                }
                                                {lang !== 'pt' &&
                                                    <li>
                                                        <button className="btn-link" onClick={() => toggleHandler('pt')}>
                                                            <img src={ptFlag} alt="PT" />
                                                            <span>Portugal</span>
                                                        </button>
                                                    </li>
                                                }
                                          </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                        
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {setOpen(!open)}}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
                
            </nav>
			{(itemId || orderId || props.path === '/') && <div className="sl-circle-bg"></div>}
		</React.Fragment>
	)
}

export default Navbar