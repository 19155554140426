import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import {useSelector, useDispatch} from 'react-redux'
import { useLocation } from '@reach/router'
import Cookies from 'js-cookie';
import SEO from '../seo'
import BookDetails from '../BookDetails'
import Loading from '../Loading'
import * as orderActions from '../../redux/actions/orderActions';
import * as incrementDownloadActions from '../../redux/actions/incrementDownloadActions';
import ErrorPage from './Error'

const Book = (props) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const orderId = props.orderId
  const order = useSelector(state => state.order)
  const incrementDownload = useSelector(state => state.incrementDownload)
  
  const dispatch = useDispatch()

  const qs = (typeof window !== `undefined`) ? window.location.search : null

  useEffect(() => {
    dispatch(orderActions.fetchOrderById(orderId, qs))
  }, [orderId, dispatch, qs])

  const orderRow = (order.payload && order.payload.order_rows && order.payload.order_rows[0]) ? order.payload.order_rows[0] : null
  const book = (orderRow && orderRow.item) ? orderRow.item : null

  // console.log(orderRow);

  const handleDownload = (e, orderRow) => {
    e.preventDefault();
    setLoading(true);

    fetch(orderRow.download_links[0].download_url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = orderRow.download_links[0].file_name;
        if (orderRow.download_links[0].download_url.match(/URLLink\.acsm/)) a.download = 'URLLink.acsm';
        a.href = blobUrl;
        a.target = '_self';
        // For Firefox https://stackoverflow.com/a/32226068
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
        if (!Cookies.get(`download`)) dispatch(incrementDownloadActions.incrementDownload(orderId, orderRow.id, location.href));
        Cookies.set(`download`, true, { path: '/' });
      })
      .catch(e => {
        setLoading(false);
        console.error(e)
      });
  }

  if (incrementDownload.error) console.log('incrementDownload Error', incrementDownload);

  if (order.status === 404) return <ErrorPage code={404} />
  else if (order.status === 401) return <ErrorPage code={401} />
  else if (order.status === 500) return <ErrorPage code={500} />
  else {
    return (
      <React.Fragment>
        <Loading loader={order.loading} />
        <SEO title="Download your book" lang={Cookies.get('lang') || 'en'} />
        
        <div className="mr-md-2 sl-m-sm">
          <div className="sl-form-box">
            <div>
              <h1 className="book-title">{book && book.title}</h1>
              {orderRow && orderRow.download_links && orderRow.download_links[0] &&
                <div>
                  {orderRow.download_links[0].download_url &&
                  <div>
                    <h3>{intl.formatMessage({ id: 'order.title' })}</h3>
                    <p>{intl.formatMessage({ id: 'order.message' })}</p>
                    <div>
                      <a href="" onClick={(e) => handleDownload(e, orderRow)} className={`btn btn-primary btn-lg btn-block mb-3 ${loading ? 'noHover disabled' : ''}`} target="_blank" rel="noreferrer">{loading && <i className="fas fa-circle-notch fa-lg fa-spin" style={{marginRight: '10px'}} />}{intl.formatMessage({ id: 'book.form.download' })}</a>
                    </div>
                  </div>
                  }
                  {orderRow.download_links[0].state === 'exceeded' &&
                    <div>
                      <h3>{intl.formatMessage({ id: 'order.title.error' })}</h3>
                      <p className="mt-2 error-box warning">
                        <FormattedMessage
                          id="order.error.exceeded"
                        />
                      </p>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>

        {book && <BookDetails book={book} error={order.error} />}
      </React.Fragment>
    )
  }
}

export default Book