import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from '@reach/router'
import Cookies from 'js-cookie';
import Loading from '../Loading'
import ErrorPage from './Error'
import * as campaignActions from '../../redux/actions/campaignActions'

const Coupon = (props) => {
  const [coupon] = useState(props.code)
  const dispatch = useDispatch()
  const campaign = useSelector(state => state.campaign)

  useEffect(() => {
    if (coupon) dispatch(campaignActions.fetchCampaign('coupon', coupon))
  }, [dispatch, coupon])

  if (coupon && campaign.status === 200) Cookies.set('coupon', coupon)

  if (coupon && campaign.payload && campaign.payload.slug_key) return <Redirect to={`/campaigns/${campaign.payload.slug_key}`} noThrow />

  if (coupon && campaign.error) return <ErrorPage code={campaign.status} />

  return (
    <Loading loader={true} />
  )
}

export default Coupon