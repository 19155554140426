import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useIntl, FormattedMessage } from 'react-intl'
import Cookies from 'js-cookie';
import Img from 'gatsby-image'
import SEO from '../seo'

const ErrorPage = (props) => {
	const intl = useIntl()
	let { code } = props;

	const images = useStaticQuery(graphql`
		query {
			notFoundImg: file(relativePath: { eq: "404.png" }) {
				childImageSharp {
					fixed(width: 700) {
						...GatsbyImageSharpFixed
					}
				}
			}
			notAuthorizedImg: file(relativePath: { eq: "401.png" }) {
				childImageSharp {
					fixed(width: 700) {
						...GatsbyImageSharpFixed
					}
				}
			}
			internalErrorImg: file(relativePath: { eq: "error.png" }) {
				childImageSharp {
					fixed(width: 700) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`)

	let errorImg

	const errorCode = (code) ? parseInt(code, 10) : 404

	switch (errorCode) {
		case 404:
			errorImg = images.notFoundImg.childImageSharp.fixed
		break
		case 401:
			errorImg = images.notAuthorizedImg.childImageSharp.fixed
		break
		default:
			errorImg = images.internalErrorImg.childImageSharp.fixed
		break
	}

	return (
		<React.Fragment>
			<SEO title={`Error ${errorCode}`} lang={Cookies.get('lang') || 'en'} />
			<div className="d-block text-center">
				<Img fixed={errorImg} alt={`Error ${errorCode}`} className="img-fluid sl-img-sm mb-4" />
				<div className="page-error-box">
					{errorCode === 404 && 
						<div>
							<h1>{intl.formatMessage({ id: 'error.404.title' })}</h1>
							<p>
								<FormattedMessage
									id="error.404.descr"
									values={{
										link: <Link to="/">
											<FormattedMessage id="error.404.descr.link" />
										</Link>
									}}
								/>
							</p>
						</div>
					}
					{errorCode === 401 && 
						<div>
							<h1>{intl.formatMessage({ id: 'error.401.title' })}</h1>
							<p>
								<FormattedMessage
									id="error.401.descr"
									values={{
										link: <a href="https://help.streetlib.com/">
											<FormattedMessage id="error.401.descr.link" />
										</a>
									}}
								/>
							</p>
						</div>
					}
					{errorCode === 403 && 
						<div>
							<h1>{intl.formatMessage({ id: 'error.403.title' })}</h1>
							<p>
								<FormattedMessage
									id="error.403.descr"
									values={{
										link: <a href="https://help.streetlib.com/">
											<FormattedMessage id="error.403.descr.link" />
										</a>
									}}
								/>
							</p>
						</div>
					}
					{errorCode === 500 && 
						<div>
							<h1>{intl.formatMessage({ id: 'error.500.title' })}</h1>
							<p>
								<FormattedMessage
									id="error.500.descr"
									values={{
										link: <a href="https://help.streetlib.com/">
											<FormattedMessage id="error.500.descr.link" />
										</a>
									}}
								/>
							</p>
						</div>
					}
				</div>
			</div>
		</React.Fragment>
	)
}

export default ErrorPage
