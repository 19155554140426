import React from 'react';
import { IntlProvider } from 'react-intl';
import Cookies from 'js-cookie';

import enTranslation from '../locales/en.json';
import itTranslation from '../locales/it.json';
import ptTranslation from '../locales/pt.json';

const messages = {
    'en': enTranslation,
    'it': itTranslation,
    'pt': ptTranslation
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    const cookielang = Cookies.get('lang');

    let browserLang;
    if (typeof window !== `undefined`) browserLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
    if (browserLang) {
      browserLang = browserLang.split('-')[0];
    }

    if (cookielang) browserLang = cookielang;
    if (browserLang && browserLang !== 'it' && browserLang !== 'en' && browserLang !== 'pt') browserLang = 'en';
    if (browserLang) {
      Cookies.set('lang', browserLang);
    }

    const lang = browserLang;

    this.switchToLocale = (locale) => {
      this.setState({ locale: locale, messages: messages[locale] });
    }

    // pass everything in state to avoid creating object inside render method (like explained in the documentation)
    this.state = {
      locale: lang,
      messages: messages[lang],
      switchToLocale: this.switchToLocale
    };

    Cookies.remove(`download`);
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
      <Context.Provider value={this.state}>
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale='en'
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  }
}

export { IntlProviderWrapper, Context as IntlContext };
