import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import { IntlProviderWrapper } from './IntlContext';
import './layout.css';

import Navbar from './Navbar'
import Footer from './Footer'
import CookieBanner from './CookieBanner'

export function Layout({ children }) {
  return (
    <IntlProviderWrapper>
      <Navbar {...children.props} />
      <main className="d-md-flex flex-md-equal w-100 pl-md-3 container">
        {children}
      </main>
      <Footer {...children.props} />
      <CookieBanner />
    </IntlProviderWrapper>
  )
}

export default Layout
