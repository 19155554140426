import React from 'react'
import { Router, Redirect } from '@reach/router'

import PublicRoute from '../components/Routes/PublicRoute';
import { Home, Coupon, Campaign, Book, CampaignPrivacy, Order, Privacy, Tos, ErrorPage } from '../components/Pages';
import { history } from '../store';

const App = (props) => {

  return (
    <Router history={history}>
      <PublicRoute path="/" component={Home} />
      <PublicRoute path="/coupon/:code" component={Coupon} />
      <Redirect from="/campaigns" to="/" noThrow />
      <Redirect from="/campaigns/:slug" to="/campaigns/:slug/page/1" noThrow />
      <PublicRoute path="/campaigns/:slug/page/:page" component={Campaign} />
      <PublicRoute path="/campaigns/:slug/books/:itemId" component={Book} />
      <PublicRoute path="/campaigns/:slug/privacy" component={CampaignPrivacy} />
      <PublicRoute path="/orders/:orderId" component={Order} />
      <PublicRoute path="/privacy" component={Privacy} />
      <PublicRoute path="/tos" component={Tos} />
      <PublicRoute path="/error/:code" component={ErrorPage} />
      <PublicRoute component={ErrorPage} default />
    </Router>
  )
}

export default App