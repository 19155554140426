import React, { useEffect } from 'react'
import { useIntl } from 'react-intl';

const AvailableFormats = ({ formats, setFormat }) => {
	const intl = useIntl();

	useEffect(() => {
		if (formats.length === 1) {
			setFormat(formats[0].name)
			var event = new MouseEvent('click', {
			 'view': window, 
			 'bubbles': true, 
			 'cancelable': false
			});
			var node = document.getElementById(formats[0].name);
			node.dispatchEvent(event);
		}
	}, [formats, setFormat])

	return (
		<React.Fragment>
			<h6>{intl.formatMessage({ id: 'book.form.formats' })}</h6>
			<ul className="sl-inline-radio">
				{formats.map((format) =>
					<li key={format.formatId}>
						<div className="custom-control custom-radio">
							<input type="radio" className="custom-control-input" id={format.name} name="format" required onChange={(e) => setFormat(e.target.id)} />
							<label className="custom-control-label" htmlFor={format.name} data-tip={format.description}><i className={`${format.icon} fa-2x`} />
							</label>
						</div>
					</li>
				)}
			</ul>
		</React.Fragment>
	)
}

export default AvailableFormats